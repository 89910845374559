import { riskCriticalities } from '../../constants/criticalities'
import TimeHelpers from '../../factories/TimeHelpers'
import { getRiskScoreCriticality } from '../../constants/criticalities'
import moment from 'moment'
import Utilities from '@risk3sixty/utilities'
const RRule = Utilities.Dates.RRule

export default {
  vendormanagement: {
    error: null,

    riskScores: [
      {
        value: (v) => v < 4,
        header: 'Low Risk',
        variant: 'success',
        policy_text:
          'Business owners may determine if IT due diligence is required.',
      },
      {
        value: (v) => v >= 4 && v <= 7,
        header: 'Medium Risk',
        variant: 'info',
        policy_text:
          'Formal IT due diligence upon initial vendor onboarding is required.',
      },
      {
        value: (v) => v >= 8 && v < 15,
        header: 'High Risk',
        variant: 'warning',
        policy_text:
          '	Due diligence on a rotating timeline and upon significant change in circumstances. Business owners must provide written business justification or mitigating control factors to move forward with vendor.',
      },
      {
        value: (v) => v >= 15,
        header: 'Extreme Risk',
        variant: 'danger',
        policy_text:
          'Annual due diligence is required. Business owners must provide written business need or mitigating control factorsto move forward with vendor.',
      },
    ],
    impactScores: [],
    likelihoodScores: [],
    costScores: [],
    criticalityScores: [],

    categories: [],
    vendors: [],
    vendorStatuses: [],
    currentQuestionnaireAndSections: null,
    questionnaireStatuses: [],
    reviewStatuses: [],
    reviewCadences: [],
    questionnaireChoiceTemplates: [],
    showQuestionnaireTemplates: false,

    showAllVendors: false,

    teamVendors: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    teamVendorFilters: {
      categories: [],
      owners: [],
      riskScores: [],
      tags: [],
      statuses: ['not_set', 'pending', 'approved_active'],
      sortLastReviewed: true,
    },

    teamVendorSorting: {
      sortNextReviewed: false,
    },

    currentVendor: {
      comments: [],
      files: [],
      owners: [],
      tags: [],
      vendor_users: [],
      pmTimeline: [],
    },

    questionnaireFilters: {
      vendors: [],
      reviewers: [],
      statuses: ['draft', 'sent', 'in_progress', 'submitted', 'under_review'],
      tags: [],
      start: null,
      end: null,
    },

    questionnaires: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    allQuestionnaires: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    questionnaireTemplates: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    currentQuestionnaire: {
      comments: [],
      files: [],
      tags: [],
      users: [],
      pmTimeline: [],
    },

    reviewFilters: {
      vendors: [],
      assignees: [],
      statuses: ['draft', 'in_progress', 'complete'],
      tags: [],
      start: null,
      end: null,
    },

    reviews: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    allReviews: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    currentReview: {
      comments: [],
      files: [],
      tags: [],
      assignees: [],
    },

    questionnaireQuestionTypes: [
      { value: 'free_form', text: 'Free Response' },
      {
        value: 'multiple_choice_single',
        text: 'Single Choice',
      },
      {
        value: 'multiple_choice_multiple',
        text: 'Multiple Choice',
      },
    ],

    getSingleDatumVariant(score) {
      if (score == null) return 'gray'

      if (score <= 1) return 'success'

      if (score <= 2) return 'minimal'

      if (score <= 3) return 'info'

      if (score <= 4) return 'warning'

      if (score > 4) return 'danger'

      return 'gray'
    },

    getRiskScoreVariant(score) {
      if (score === null) return 'gray'

      if (score <= 3) return 'success'

      if (score <= 6) return 'info'

      if (score <= 12) return 'warning'

      if (score > 12) return 'danger'

      return 'gray'
    },

    getRiskVariantByCriticality(criticality) {
      switch (criticality) {
        case riskCriticalities.LOW.text:
          return riskCriticalities.LOW.variant
        case riskCriticalities.MEDIUM.text:
          return riskCriticalities.MEDIUM.variant
        case riskCriticalities.HIGH.text:
          return riskCriticalities.HIGH.variant
        case riskCriticalities.EXTREME.text:
          return riskCriticalities.EXTREME.variant
        default:
          return riskCriticalities.NOT_SET.variant
      }
    },

    teamVendorsRiskScoreInfo(teamVendors) {
      const teamVendorFilter = (filter) => {
        return teamVendors.filter((i) => filter(i.risk_score))
      }

      return this.riskScores.map((risk) => {
        const count = (teamVendorFilter(risk.value) || []).length
        return {
          key: risk.header,
          value: count,
          subvalue: `${count} vendors`,
          variant: risk.variant,
        }
      })
    },

    questionnairesStatusInfo(questionnaires, statuses) {
      return statuses.map((status) => {
        const count = questionnaires.filter(
          (q) => q.status == status.value
        ).length
        return {
          key: status.text,
          status: status.value,
          value: count,
          subvalue: `${count} questionnaires`,
          variant: status.variant,
          tooltip: status.description,
        }
      })
    },

    reviewsStatusInfo(reviews, statuses) {
      return statuses.map((status) => {
        const count = reviews.filter((q) => q.status == status.value).length
        return {
          key: status.text,
          status: status.value,
          value: count,
          subvalue: `${count} reviews`,
          variant: status.variant,
          tooltip: status.description,
        }
      })
    },

    getLastReviewed(reviews) {
      const lastCompleted = (reviews || [])
        .filter((review) => {
          return review.status == 'complete'
        })
        .sort((a, b) => {
          return new Date(b.reviewed_at) - new Date(a.reviewed_at)
        })[0]

      return lastCompleted && lastCompleted.reviewed_at
        ? TimeHelpers.getFormattedDate(lastCompleted.reviewed_at)
        : null
    },

    sortVendors(vendorList) {
      for (let key in this.teamVendorSorting) {
        if (this.teamVendorSorting[key]) {
          if (key == 'sortNextReviewed') {
            vendorList.sort(
              (a, b) =>
                this.getDaysToNextReviewDate(a) -
                this.getDaysToNextReviewDate(b)
            )
          }
        }
      }
      return vendorList
    },

    getDaysToNextReviewDate(vendor) {
      const nextReviewDate = this.getNextReviewDate(vendor)
      const days = moment(nextReviewDate).diff(moment(), 'days')
      return isNaN(days) ? 99999 : days
    },

    getNextReviewDate(vendor) {
      if (
        vendor.status == 'approved_inactive' ||
        vendor.status == 'denied' ||
        vendor.status == 'archived'
      )
        return 'N/A'

      const lastReviewDate = vendor.last_review_date
      const riskLevel = getRiskScoreCriticality(vendor.risk_score)
      const stateVendorManagement = this
      const reviewCadence = stateVendorManagement.reviewCadences.find(
        (rc) =>
          (rc.header || '').toLowerCase() === (riskLevel || '').toLowerCase()
      )
      if (!lastReviewDate || !(reviewCadence && reviewCadence.policy_text))
        return 'None...'

      const reviewPolicy = RRule.fromString(reviewCadence.policy_text)
      const { freq, interval } = reviewPolicy.options

      const intervalsFromLastReivewed = new RRule({
        dtstart: new Date(lastReviewDate),
        freq,
        interval,
      })
      const nextReviewDate = intervalsFromLastReivewed.after(
        new Date(lastReviewDate)
      )
      return this.getFormattedDate(nextReviewDate)
    },

    getFormattedDate(date) {
      return TimeHelpers.getFormattedDate(date)
    },
  },
}

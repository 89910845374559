export default {
  SET_RISK_REGISTER_ERROR(state, error) {
    state.riskregister.error = error
  },

  SET_RISK_REGISTER_STATUSES(state, statuses) {
    state.riskregister.statuses = statuses
    state.riskregister.itemFilters.status =
      state.riskregister.itemFilters.status.length > 0
        ? state.riskregister.itemFilters.status
        : statuses.filter((s) => s.value !== 'complete').map((s) => s.value)
  },

  SET_RISK_REGISTER_TREATMENT_DECISIONS(state, decisions) {
    state.riskregister.decisions = decisions
  },

  SET_RISK_REGISTER_POLICIES(state, policiesByKey) {
    state.riskregister.acceptableRisk = policiesByKey.acceptable_risk
    state.riskregister.impactScores = policiesByKey.impact
    state.riskregister.likelihoodScores = policiesByKey.likelihood
    state.riskregister.costScores = policiesByKey.cost
    state.riskregister.effortScores = policiesByKey.effort
  },

  SET_RISK_REGISTER_CATEGORIES(state, cats) {
    state.riskregister.categories = cats
  },

  SET_RISK_REGISTERS(state, registers) {
    state.riskregister.riskRegisters = registers
  },

  SET_RISK_REGISTER(state, record) {
    state.riskregister.currentRegister = record
  },

  SET_RISK_REGISTER_ASSESSMENT_MAPPINGS(state, mappings) {
    state.riskregister.currentRegisterAssessmentMappings = mappings
  },

  SET_RISK_REGISTER_ITEM(state, record) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      record,
    }
  },

  SET_RISK_REGISTER_ITEM_METRIC_HISTORY(state, history) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      history,
    }
  },

  SET_RISK_REGISTER_ITEM_COMMENTS(state, comments) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      comments,
    }
  },

  SET_RISK_REGISTER_ITEM_FILES(state, files) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      files,
    }
  },

  SET_RISK_REGISTER_ITEM_OWNERS(state, owners) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      owners,
    }
  },

  SET_RISK_REGISTER_ITEM_TAGS(state, tags) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      tags,
    }
  },

  SET_RISK_REGISTER_ITEM_CHANGE_LOGS(state, changeLogs) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      changeLogs,
    }
  },

  SET_RISK_REGISTER_ITEM_PMTIMELINE(state, pmTimeline) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      pmTimeline,
    }
  },

  SET_RISK_REGISTER_ITEM_JIRA_MAPPINGS(state, jiraMappings) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      jiraMappings,
    }
  },

  SET_RISK_REGISTER_ITEM_EVENTS(state, calendarEvents) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      calendarEvents,
    }
  },

  SET_RISK_REGISTER_ITEM_VENDORS(state, teamVendors) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      teamVendors,
    }
  },

  SET_RISK_REGISTER_ITEM_INVENTORY_ITEMS(state, inventoryItems) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      inventoryItems,
    }
  },

  SET_RISK_REGISTER_ITEM_CONTROLS(state, controls) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      controls,
    }
  },

  SET_RISK_REGISTER_ITEM_FORMAL_FINDINGS(state, formalFindings) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      formalFindings,
    }
  },

  SET_RISK_REGISTER_ITEM_PROJECT_TASKS(state, projectTasks) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      projectTasks,
    }
  },

  SET_RISK_REGISTER_ITEM_MY_CONTROLS(state, myControls) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      myControls,
    }
  },

  SET_RISK_REGISTER_ITEM_SCOPE_GROUPS(state, scopeGroups) {
    state.riskregister.currentItem = {
      ...state.riskregister.currentItem,
      scope_groups: scopeGroups,
    }
  },

  SET_RISK_REGISTER_SCOPE_GROUPS(state, scopeGroups) {
    state.riskregister.allScopeGroupsInRegister = scopeGroups
  },

  SET_RISK_REGISTER_TAGS(state, tags) {
    state.riskregister.allTagsInRegister = tags
  },

  SET_RISK_REGISTER_ITEMS(state, info) {
    state.riskregister.items = info
  },

  SET_RISK_REGISTER_ITEMS_PAGE(state, newPage) {
    state.riskregister.items.currentPage = newPage
  },

  SET_RISK_REGISTER_ITEMS_PER_PAGE(state, newPerPage) {
    state.riskregister.items.perPage = newPerPage
  },

  CLEAR_RISK_REGISTER_ITEM_FILTERS(state) {
    Object.keys(state.riskregister.itemFilters).forEach((key) => {
      state.riskregister.itemFilters[key] = null
    })
  },

  SET_RISK_REGISTER_ITEM_FILTER(state, { key, val }) {
    state.riskregister.itemFilters[key] = val
  },

  SET_RISK_REGISTER_DASHBOARD_FILTER(state, { key, val }) {
    state.riskregister.dashboardFilters[key] = val
  },

  CLEAR_RISK_REGISTER_FILTERS(state) {
    state.riskregister.itemFilters = {
      category: [],
      riskScore: [],
      decision: [],
      scope: [],
      status: [],
      tag: [],
      user: [],
      assessment: [],
      register: [],
      startDate: null,
      endDate: null,
      completeDate: null,
      identifiedDate: null,
    }
  },

  CLEAR_RISK_REGISTER_DASHBOARD_FILTERS(state) {
    state.riskregister.dashboardFilters = {
      assessment: [],
      category: [],
      decision: [],
      register: [],
      riskScore: [],
      scope: [],
      status: [],
      tag: [],
      user: [],
      // "open", "complete", or null
      limitByDateType: null,
      risksOpenedDateRange: '3M',
      risksMarkedCompleteDateRange: '3M',
      riskHistoryDateRange: '3M',
    }
  },

  SET_RISK_REGISTER_SORTING(state, { key, val }) {
    state.riskregister.sorting = {}
    state.riskregister.sorting[key] = val
  },

  SET_RISK_REGISTER_RISK_SEARCH(state, input) {
    state.riskregister.riskSearch = input
  },

  SET_RISK_REGISTER_FRONTEND_PAGINATION_OBJ(state, fields) {
    state.riskregister.frontendPaginatedData = {
      ...state.riskregister.frontendPaginatedData,
      ...fields,
    }
  },

  SET_RISK_REGISTER_DASHBOARD_LOADING(state, { key, val }) {
    state.riskregister.dashboardLoading[key] = val
  },

  SET_RISK_REGISTER_DASHBOARD_DATA(state, { key, val }) {
    state.riskregister.dashboardData[key] = val
  },

  SET_RISK_REGISTER_ALL_OWNERS(state, owners) {
    state.riskregister.allRiskRegisterOwners = owners
  },

  SET_RISK_REGISTER_DASHBOARD_BY_X_GROUP_BY(state, groupBy) {
    state.riskregister.dashboardRiskByXGroupBy = groupBy
  },
}
